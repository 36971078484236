<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    },
    es: {
      'Courses': 'Cursos',
      'Buy': 'Comprar',
    }
  },
  components: {
    Layout
  },
  data() {
    return {
      courses: {
        loading: true,
        errored: false,
        empty: false,
        list: null,
      },
    };
  },
  methods: {
    getCourses() {
      this.courses.loading = true
      this.courses.list = null

      api
        .get('course')
        .then(response => {
          if (response.data.status=='success') {
            this.courses.list = response.data.list
            this.courses.loading = false
          } else {
            this.courses.list = null
          }
        })
        .catch(error => {
          this.courses.errored = error
          this.courses.loading = false
        })
        .finally(() => {
          this.courses.loading = false
        })
    }
  },
  mounted() {
    this.getCourses()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-22">{{ t('Courses') }}</h4>
        </div>
      </div>
    </div>
    <div v-if="courses.loading" class="text-center">
      <b-spinner small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
    </div>
    <div v-else-if="!courses.list || courses.list && courses.list.length == 0" class="card">
      <div class="card-body">
        <div class="text-center py-5">
          <i class="far fa-sad-cry font-size-24"></i><br>
          ainda não temos nenhum curso disponível
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-6 col-xl-4" v-for="(course,id) in courses.list" :key="id">
        <router-link tag="a" :to="'/courses/' + course.slug" class="course w-100 text-dark">
          <div class="card rounded w-100">
            <div class="card-body card-h-fix p-0 text-center">
              <img class="course-cover " :src="course.cover" />
              <div class="p-3">
                <h5 class="m-0">{{ course.title }}</h5>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.course .card-body {
  padding: 30px;
}

.course-cover {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  width: 100%;
}
</style>